import { Entities } from '@inwink/entities/entities';
import { guid } from '@inwink/utils/methods/guid';
import { States } from '@@services/services';
import { eventUserModule, userMessageModule } from '@@routes/appmodules';
import { actionQueue } from '@@actionsqueue/index';

function exhibitorActionQueue() {
    return import('@@event/actionsqueue/actionqueue.favoriteexhibitor');
}

function meetingAPI() {
    return import('../../api/meetings');
}

function tracking() {
    return import("@@services/tracking/actions");
}

export const exhibitorActions = {
    toggleFavorite: (exhibitorId: string, i18nHelper: Entities.i18nHelper, elt: HTMLElement) => {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            const data = state.user && state.user.currentUser && state.user.currentUser.data;
            const hasFav = data && data.favoriteExhibitors && data.favoriteExhibitors.data
                .filter((e) => e.status === 'Active' && e.exhibitorId === exhibitorId)[0];

            if (hasFav) {
                return favoriteExhibitorActions.removeExhibitorFromFavorites(exhibitorId)(dispatch, getState) as Promise<any>;
            }
            return favoriteExhibitorActions.addExhibitorToFavorites(exhibitorId,
                i18nHelper, elt)(dispatch, getState) as Promise<any>;
        };
    },

    requestMeeting: (
        i18nHelper: Entities.i18nHelper,
        exhibitor: Entities.IExhibitor,
        businessMeeting: boolean,
        elt: HTMLElement,
        selectedSpace?: string
    ) => {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            return exhibitorActions.createRequestMeeting(
                i18nHelper, exhibitor, businessMeeting, elt, selectedSpace
            )(dispatch, getState)
                .then((meetingrequest: any) => {
                    if (meetingrequest) {
                        return meetingAPI().then((mod) => {
                            if (businessMeeting) {
                                return mod.requestExhibitorBusinessMeeting(
                                    currentstate.event.requestManagers.apiFront,
                                    currentstate.event.eventid,
                                    exhibitor.id,
                                    meetingrequest.meetingKind,
                                    meetingrequest.title,
                                    meetingrequest.message,
                                    {
                                        type: 'meetingrequest',
                                        payload: {
                                            capacity: meetingrequest.capacity,
                                            meetingKind: meetingrequest.meetingKind,
                                            location: meetingrequest.location,
                                            timeslots: meetingrequest.timeslots
                                        }
                                    },
                                    meetingrequest.exhibitorAccountId,
                                    meetingrequest.timeslots[0].startDate,
                                    meetingrequest.timeslots[0].endDate,
                                    meetingrequest.timeslots[0].isOnline,
                                    meetingrequest.roomId
                                );
                            }

                            return mod.requestExhibitorMeeting(
                                currentstate.event.requestManagers.apiFront,
                                currentstate.event.eventid,
                                exhibitor.id,
                                meetingrequest.meetingKind,
                                meetingrequest.title,
                                meetingrequest.message,
                                {
                                    type: 'meetingrequest',
                                    payload: {
                                        capacity: meetingrequest.capacity,
                                        meetingKind: meetingrequest.meetingKind,
                                        location: meetingrequest.location,
                                        timeslots: meetingrequest.timeslots
                                    }
                                },
                                meetingrequest.exhibitorAccountId
                            );
                        });
                    }
                });
        };
    },
    createRequestMeeting: (
        i18nHelper: Entities.i18nHelper,
        exhibitor: Entities.IExhibitor,
        businessMeeting: boolean,
        elt: HTMLElement,
        selectedSpace?: string
    ) => {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            if (!currentstate.user.currentUser) {
                userMessageModule().then((mod) => {
                    mod.loginActions.notifyRequireLogin(elt, i18nHelper, null, exhibitor.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }
            if (!currentstate.user.currentUser.detail.id || !currentstate.user.currentUser.detail.isRegistered) {
                userMessageModule().then((mod) => {
                    mod.messageRegistrationActions.notifyRequireRegistration(elt, i18nHelper,
                        null, exhibitor.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }

            return eventUserModule().then((mod) => mod.networkingModule(dispatch, getState)).then((mod) => {
                const action = businessMeeting
                    ? mod.networkingActions.showBusinessMeetingRequest(i18nHelper, exhibitor, selectedSpace)(dispatch)
                    : mod.networkingActions.showMeetingRequest(i18nHelper, null, true, null, exhibitor)(dispatch, getState);

                return action;
            });
        };
    },
    contact: (exhibitor: Entities.IExhibitor, i18nHelper: Entities.i18nHelper, elt: HTMLElement) => {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            if (!currentstate.user.currentUser) {
                userMessageModule().then((mod) => {
                    mod.loginActions.notifyRequireLogin(elt, i18nHelper, null, exhibitor.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }
            if (!currentstate.user.currentUser.detail.id || !currentstate.user.currentUser.detail.isRegistered) {
                userMessageModule().then((mod) => {
                    mod.messageRegistrationActions.notifyRequireRegistration(elt, i18nHelper,
                        null, exhibitor.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }

            const userstate = currentstate.user;
            return eventUserModule().then((mod) => mod.networkingModule(dispatch, getState)).then((networkingMod) => {
                const discussion = userstate?.currentUser
                    && userstate.currentUser.data.discussionThreads.data.filter((dt) => {
                        return dt.thread.participants.length === 2
                            && dt.thread.participants.some((eu) => eu.exhibitor && eu.exhibitor.id === exhibitor.id)
                            && !dt.thread.meetingId;
                    })[0];

                if (!discussion) {
                    const tempId = guid();
                    const msg = {
                        id: tempId,
                        $tempId: tempId,
                        hasUnread: false,
                        lastMessage: null,
                        lastMessageDate: null,
                        lastReadDate: null,
                        thread: {
                            id: tempId,
                            $transient: new Date(),
                            $tempId: tempId,
                            creationDate: null,
                            lastReadDate: null,
                            participants: [{
                                person: {
                                    id: userstate.currentUser.detail.id,
                                    firstname: userstate.currentUser.detail.firstname,
                                    lastname: userstate.currentUser.detail.lastname,
                                    photo: userstate.currentUser.detail.photo
                                }
                            },
                            {
                                exhibitor
                            }]
                        }
                    } as Entities.IDiscussionThreadWrapper;

                    userstate.currentUser.data.discussionThreads.insert(msg);

                    return userstate.currentUser.data.save().then(() => {
                        const disc = userstate?.currentUser
                            && userstate.currentUser.data.discussionThreads.data.filter((dt) => dt.id === tempId)[0];
                        const showDiscussionPromise = networkingMod.contactRequestActions.showDiscussion(
                            i18nHelper,
                            disc
                        )(dispatch, getState);
                        return showDiscussionPromise.then(() => {
                            return exhibitorActions.showSharingModal(exhibitor, i18nHelper)(dispatch, getState).then(() => true);
                        });
                    });
                }
                const showDiscussionPromise = networkingMod.contactRequestActions.showDiscussion(
                    i18nHelper,
                    discussion
                )(dispatch, getState);
                return showDiscussionPromise.then(() => {
                    return exhibitorActions.showSharingModal(exhibitor, i18nHelper)(dispatch, getState).then(() => true);
                });
            });
        };
    },
    showSharingModal: (exhibitor: Entities.IExhibitor, i18nHelper: Entities.i18nHelper) => {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            const esecic = currentstate.event.detail.configuration?.companion?.headerSettings?.enabledShareExhibitorContactInChat;
            if (esecic) {
                const exhibitorFavorite = currentstate?.user?.currentUser?.data?.favoriteExhibitors.data.find(
                    (f) => f.exhibitorId === exhibitor.id
                );
                if (!exhibitorFavorite || !exhibitorFavorite.allowSharingInfo) {
                    return userMessageModule().then((mod) => {
                        return mod.userMessageActions.confirm(i18nHelper, {
                            title: "exhibitorsharinginfobanner.modal.title",
                            message: "exhibitorsharinginfobanner.modal.message",
                            messageInject: exhibitor
                        })(dispatch, getState).then((res) => {
                            if (res) {
                                favoriteExhibitorActions.allowSharingInfoWithExhibitor(
                                    exhibitor.id
                                )(dispatch, getState);
                            }
                        });
                    });
                }
            }
            return Promise.resolve();
        };   
    }
};

export const favoriteExhibitorActions = {
    addExhibitorToFavorites: (exhibitorId: string, i18nHelper: Entities.i18nHelper,
        elt: HTMLElement) => (dispatch, getState: () => States.IAppState) => {
        const currentstate = getState();
        if (!currentstate.user.currentUser) {
            userMessageModule().then((mod) => {
                mod.loginActions.notifyRequireLogin(elt, i18nHelper, null, exhibitorId)(dispatch, getState);
            });
            return Promise.resolve(true);
        }
        if (!currentstate.user.currentUser.detail.id || !currentstate.user.currentUser.detail.isRegistered) {
            userMessageModule().then((mod) => {
                mod.messageRegistrationActions.notifyRequireRegistration(elt, i18nHelper, null, exhibitorId)(dispatch, getState);
            });
            return Promise.resolve(true);
        }

        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitors', 'add', exhibitorId)
        );
        return exhibitorActionQueue().then((mod) => {
            return mod.addExhibitorToFavorites(dispatch, getState, exhibitorId)
                .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
        });
    },

    addExhibitorsToFavorites: (exhibitorIds: string[]) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitorsbatch', 'add')
        );
        return exhibitorActionQueue().then((mod) => {
            return mod.addExhibitorsToFavorites(dispatch, getState, exhibitorIds)
                .then((msgs) => {
                    if (msgs?.length) {
                        msgs.forEach((msg) => actionQueue.enqueue(msg, dispatch, getState));
                    }
                });
        });
    },

    removeExhibitorFromFavorites: (exhibitorId: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitors', 'remove', exhibitorId)
        );
        return exhibitorActionQueue().then((mod) => {
            return mod.removeExhibitorFromFavorites(dispatch, getState, exhibitorId)
                .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
        });
    },

    allowSharingInfoWithExhibitor: (exhibitorId: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitors', 'allowsharinginfo', exhibitorId)
        );
        return exhibitorActionQueue().then((mod) => {
            return mod.allowSharingInfoExhibitor(dispatch, getState, exhibitorId)
                .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
        });
    }
};
